.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: fixed;
    padding: 32px;
    background-color: white;
    width: 100%;
    max-width: 640px;
}
.popup-inner h3{
    margin-top: 25px ;
    color: blue;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    
}

@media screen and (max-width: 850px) {
    .popup-inner {
        padding: 16px;
        width: 100%;
        max-width: 350px;
        margin-top: 15px;
        
    }
    .popup-inner .close-btn {
        position:absolute;
        top: 8px;
        right: 8px;
        
    }
}