.cl{
    margin: 2rem 4rem;
    color: #2a2a2a;
}

.cl h1 {
    font-size: 2rem;
    text-align:  center;
    
}

.cl p {
    text-align:  center;
}

/* .sercard{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;  
    align-items:center;
    text-align: center;

} */

/* .t-card{
    width: 380px;
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 8rem;
   
} */

.cl img{
    height: 300px;
    width: 250px;
    overflow: hidden;
    border-radius: 7px;
    margin-right: 2px;
}

.cl.btn{
    align-items: center;
}




.custom-btn{
    margin-top: 10px;
}

.t-image button{
    margin-left: 30px;
   
}

.t-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
    
}

.cl img:hover img{
    transform: scale(1.3);
}

.t-card h3{
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
}

.t-card button{
    align-items: center;
    justify-content: center;
    background-color: blue;
}

/* //////////////////////////////////////////// */

.card-group {
    display: flex;
    justify-content: space-between; /* ou d'autres valeurs selon votre mise en page */
}
  
.card {
    flex: 1; /* Pour que chaque carte occupe le même espace */
}

.card-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* ou ajustez en fonction de vos besoins */
    gap: 16px; /* ou une autre valeur pour l'espace entre les cartes */
}
/* /////////////////////////////////////////////// */
/* @media screen and (max-width:850px){
    .ser{
        margin: 4rem 2rem;
    }
    .sercard{
        flex-direction: column;
        
    }  
    .t-card{
        width: 50% !important;
        margin-bottom: 1.5rem;   
    }  
    
} */


