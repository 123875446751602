.hero{
    width: 100%;
    height: 80vh;
    position: relative;
}

.serv{
    width: 100%;
    height: 70vh;
    position: relative;
}

img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    color:blue;
    
}

/* .hero-HeroImg{
    width: max-content;
    height: 50vh;
} */

.hero-text p{
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem 0;
    text-align: center;
    
    
}


.hero-text .show{
    text-decoration:none ;
    background: #ff1d1d;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    margin-left: 200px;
}


@media screen and (max-width: 850px) {
    .hero-text h1{
        padding: 10px 20px;
        font-size: 2rem;
        text-align: center;
    }
    .hero-text p{
        font-size: 1rem;
        padding: 0 0 2rem 0;
    }
    .hero-text .show{
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 0.9rem;
        margin-left: 3.8rem;    
                
    }

    
    
}