.NavbarItems{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 90px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 49%;
    transform: translate(-50%);
    z-index: 9999;
    
}

.navbar-logo{
    font-size: 2rem;
    color: #222;
    cursor: pointer;
    margin-left:0%;
}

.costumlink{
    text-decoration: none;
    color:#0528c1;
}
.navbar-logo-image{
    max-width: 90px;
    width: 90px;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(4 , auto);
    grid-gap: 20px;
    list-style: none;
    align-items: center;
    margin-top: 25px;
}
.nav-links{
    position: relative;
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    transition: all .3s ease-in-out;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    
}



.nav-links i{
   padding-right: 10px;
   text-decoration: none;
    color: #151516;
    position: relative;
}

.nav-links:hover{ 
     background-color: #01959a; 
     color: aliceblue; 
    border-radius: 4px;
    transition: all .2s ease-in-out;
    
}



/* Original CSS for the submenu - remove the padding-top and z-index properties */
.services-submenu {
    display: none;
    position: absolute;
    top: 52px;
    /* left: 0%; */
    /* right: 10%; */
    width: 180% ;
    text-decoration: none;
    color: #01959a;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
    white-space: nowrap;
    background-color: #01959a;
    z-index: 10000;
}

.services-submenu i {
    padding-right: 10px;
    text-decoration:none ;
    color: black;
    top: 0;
}


/* Updated CSS to hide the submenu when not hovering over the "Services" link */
.nav-links:hover .services-submenu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    max-height: 1000px;
    transition: all 0.3s ease-in-out;
    
}

.services-submenu {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

/* Add a transition to improve the animation */
.services-submenu ul {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Adjust the padding to control the submenu's position */
.services-submenu ul {
    list-style-type: none;
    padding: 10px;
}

.services-submenu li {
    list-style-type: none;
    padding: 5px 0;
    
}

/* ///////////////////////////////// */
.services-submenu li a {
    text-decoration: none;
    color: #01959a;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    white-space: nowrap;
}

.services-submenu li a:hover {
    background-color:blueviolet;
    border-radius: 10px;
    
}

.nav-links-mobile{
    display: none;
}
button{
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

button:hover{
    background-color: #0f0fd2;
    color:#fff  ;
}

.menu-icons{
    display: none ;
}

@media screen and (max-width: 850px) {
    .NavbarItems{
        z-index: 99;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        background: #fff;
        width: 100%;
        align-items: center;
       
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 70px 0 20px 0;
        margin: 0;
        transition: 0.3s ease-in-out ;
        border-radius: 13px;
        
    }
    .nav-menu.active {
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out ;
    }
    /* ---------------------------------- */

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.1rem;
        padding: 1rem ;
    }
    .nav-links:hover{
        background: #01959a;
        transition: none;
    }

    .nav-links:hover .services-submenu{
        width: 230px;
        height: 400px;
        border-radius: 10px;
        left: 115px;
        text-align: left;
    }

    button{
        display: none;
    } 
    
    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1rem;
        margin: auto;
        border-radius: 4px ;
        width: 70%;
        background: #01959a;
        text-decoration: none;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .menu-icons{
        display: block ;
        cursor: pointer;
    }
    .menu-icons i {
        font-size: 1.2rem;
        color:#222;
    }

}