.logo{
    width: 100px; /* Ajustez la largeur selon vos besoins */
    height: auto;
    margin-right: 10px; /* Ajustez la marge selon vos besoins */     
}

.hblogo{
    font-size : 300%;
    color : #027276;
    font-family: AmstelvarAlpha;

}
.foo{
    margin-top: 50px ;
}

