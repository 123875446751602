body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.wrapper{
  /* background-color:aquamarine; */
  /* padding: 0 20px 0 20px; */
}

.input-box{
  display: flex;
  justify-content:center;
  align-items: center;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  
}

.input-filed{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 10px 0 10px;
}

.input{
  /* height: 45px; */
  width: 135%;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
  outline: none;
  margin-bottom: 20px;
  color: blue;
}

.input-box .input-filed label{
  position: absolute;
  top: 2px;
  left: 10px;
  pointer-events: none;
  transition: .5s;
}

.input-filed .input:focus ~ label{
  top: -10px;
  font-size: 13px;
}

.input-filed .input:valid ~ label{
  top: -10px;
  font-size: 13px;
  color: darkmagenta;
}

.input-filed .input:focus, .input-filed .input:valid{
  border-bottom: 1px solid darkmagenta;
}

.submit{
  border: none;
  outline: none;
  width: 140px;
  height: 40px;
  background: #743ae1;
  transition: .4s;
  border-radius: 4px;
}

.form-container {
  background-color: #87ceeb  ; /* Couleur de fond de votre choix */
  padding: 20px; /* Espace intérieur autour du formulaire */
  border-radius: 15px; /* Bord arrondi pour un look plus esthétique */
  width: 50%; /* Ajustez la largeur selon vos préférences */
  margin: 0 auto; /* Centre le conteneur horizontalement */
}


.fixed-container{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll; /* Si le contenu est trop grand, activez la barre de défilement vertical */
}

.image-boutique {
  width: 100%; /* La largeur de l'image est définie à 100% pour qu'elle soit responsive */
  height: auto; /* La hauteur est automatiquement ajustée pour maintenir les proportions de l'image */
}

.equal-img {
  width: 100%;
  height: 200px; /* Ajustez la hauteur souhaitée */
  object-fit: cover; /* Pour maintenir les proportions et remplir l'espace */
}

@media screen and (max-width: 850px) {
  .input{
    width: 100%;
  }
}